export const BACKEND_URL = 'https://app.yard.bot'
// export const BACKEND_URL = 'http://localhost:8080'

export const REDIRECT_URL = 'https://yard.bot'

export const STRIPE_PUBLIC_KEY = 'pk_live_51JtNhlGlJVQuj2bmplrLcnNh9DYd3gPvI1p9OF2FKCVaq0R47e8mUCP1jO2sjp1wfLYMvslAdMVxcE1rNYwxiEPl00CBSY1PtJ'
export const PRIVACY_POLICY_DOCUMENT = 'https://docs.google.com/document/d/1I8NN9GLh1xOiwzo_o9p247DEEEeNAf9r80hg8Avi1aQ'
export const PAYMENT_POLICY_LINK = 'https://docs.google.com/document/d/114F5e9gYtJalUMGuTPCCU52Q3C0Jq08B1uuAI5NIbDc'

export const DEPOSIT_AMOUNT = 1500
