export default function Page({ children }) {
  return <div className='d-flex flex-column m-h-100vh position-relative'>
    <div className='pt-3 pb-3 d-flex justify-content-center bg-body-tertiary position-absolute w-100'>
      <img
        alt='Yard Robotics'
        width={100}
        src={process.env.PUBLIC_URL + '/logo23.png'}
        className='d-inline-block align-top'/>
    </div>
    <div className='d-flex justify-content-center m-h-100vh pt-7'>
      {children}
    </div>
  </div>
}