import classnames from 'classnames'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import _ from 'lodash'
import YardButton from '../yardButton'
import { PAYMENT_POLICY_LINK } from '../constants'

function priceCalculator(data, prices, notices) {
  const OVERGROWN_FEE = 100

  const price = prices.find(price => price.name === data.priceName)
  const mowPricePerMow = data.isWeekly ? price.weekly : price.biweekly
  const overgrownPrice = data.overgrown ? 100 : 0
  const totalQuadriweekly = mowPricePerMow * (data.isWeekly ? 4 : 2)

  const quote = [{
    name: 'Mowing price per mow',
    price: mowPricePerMow,
    id: 'pricePerMow',
    applicable: true
  }, {
    name: 'Overgrown fee (one-time)',
    price: overgrownPrice,
    id: 'overgrownFee',
    applicable: data.overgrown
  }, {
    name: data.overgrown ? 'Total price per mow (first service)': 'Total price per mow',
    price: mowPricePerMow + overgrownPrice,
    id: 'total',
    applicable: true
  }, {
    name: 'Amount charged every 4 weeks',
    price: totalQuadriweekly,
    id: 'totalQuadriweekly',
    applicable: true
  }]

  if (data.deals.length > 0 && data.deals[0] === 'early-bird') {
    quote.push({
      name: 'Amount charged first 4 weeks',
      price: totalQuadriweekly - 20,
      id: 'totalQuadriweeklyEarlyBird',
      applicable: true
    })
  }

  notices.push(`Billing occurs every four weeks in advance, starting after the initial service.`)
  notices.push(<a href={PAYMENT_POLICY_LINK} target='_blank' rel='noreferrer'>Please review our complete payment policy here.</a>)

  if (data.promotionalMows === 0) {
    notices.push('We do not require a contract and you can cancel at any time.')
  }

  if (data.flowerBed) {
    notices.push('We will provide a separate quote for flower bed services.')
  }

  if (data.overgrown) {
    notices.push(`We charge a one time fee of $${OVERGROWN_FEE} for overgrown lawns. After the first service, we will only charge the regular price.`)
  }

  return quote
}

export default function Confirm({data, prices, onSubmit}) {
  const notices = []
  const quote = priceCalculator(data, prices, notices)

  return <div className='d-flex flex-column'>
    <div className='mb-2'>
      <div className='display-6 mb-1'>Contact</div>
      <div className='fw-lighter'>Name: <span className='fw-bold'>{data.name}</span></div>
      <div className='fw-lighter'>Address: <span className='fw-bold'>{data.address}</span></div>
      <div className='fw-lighter'>Email: <span className='fw-bold'>{data.email}</span></div>
      <div className='fw-lighter'>Phone: <span className='fw-bold'>{data.phone}</span></div>
    </div>
    <div className='mb-2'>
      <div className='display-6 mb-1'>Scope</div>
      <div className='fw-lighter'>Lawn size: <span className='fw-bold'>{data.priceName}</span> lawn mowed <span className='fw-bold'>{data.frequency}</span></div>
      {data.flowerBed && <div><span className='fw-lighter'>Flower bed:</span> <span className='fw-bold'>{data.flowerBed ? 'Yes': 'No'}</span></div>}
      {data.overgrown && <div><span className='fw-lighter'>Overgrown:</span> <span className='fw-bold'>{data.overgrown ? 'Yes': 'No'}</span></div>}
      <div></div>
    </div>
    {(data.promotionalMows > 0) && <div className='mb-2'>
      <div className='display-6 mb-1'>Free mows</div>
      <ul className='list-group'>
        <li className='list-group-item'>We're pleased to offer you {data.promotionalMows} free mow{data.promotionalMows === 1 ? '': 's'}.</li>
        <li className='list-group-item'>Each free mowing service is provided once per billing cycle, which occurs every four weeks.</li>
        <li className='list-group-item'>We require a four-week service commitment for each free mowing session.</li>
      </ul>
    </div>}
    {data.deals.length > 0 && data.deals[0] === 'early-bird' && <div className='mb-2'>
      <div className='display-6 mb-1'>Discounted service</div>
        <ul className='list-group'>
          <li className='list-group-item'>We're pleased to offer you $20 off your first service.</li>
        </ul>
      </div>
    }
    <div className='mb-2'>
      <div className='display-6 mb-1'>Quote</div>
      {data.noQuote && <Alert className='mb-2 mt-3' variant='dark'>
        We will review the property and promptly provide you with a quote via email or phone.
      </Alert>}
      {!data.noQuote && <table className='table table-borderless'>
        <thead>
          <tr>
            <th>Name</th>
            <th className='border-secondary border-3 border-bottom-0 border-end-0 border-top-0'>Price</th>
          </tr>
        </thead>
        <tbody>
          {quote.map((q, i) => {
            if (!q.applicable) {
              return null
            }

            const classes = classnames({
              'border-secondary': true,
              'border-1': q.id !== 'totalQuadriweekly',
              'fw-bold': q.id === 'total' || q.id === 'totalQuadriweekly' || q.id === 'totalQuadriweeklyEarlyBird',
              'fw-lighter': q.id !== 'total' && q.id !== 'totalQuadriweekly' && q.id !== 'totalQuadriweeklyEarlyBird',
              'border-5': q.id === 'totalQuadriweekly',
              'border-bottom-0 border-start-0 border-end-0': true,
            })
            
            return <tr key={i} className={classes}>
              <td>{q.name}</td>
              <td className='border-secondary border-3 border-bottom-0 border-end-0 border-top-0'>${q.price}</td>
            </tr>
          })}
        </tbody>
      </table>}
      
      <div className='mb-2'>
        <div className='display-6 mb-3'>Notices</div>
        <ul className='list-group'>{notices.map((notice, i) => <li key={i} className='list-group-item'><span key={i}>{notice}</span></li>)}</ul>
      </div>
    </div>

    <Form className='d-flex flex-column' onSubmit={e => {
      e.preventDefault()
      onSubmit(_.extend(data, {quote}))
    }}>

      <YardButton className='mt-2 mb-3' variant='primary' type='submit'>Next</YardButton>
    </Form>
  </div>
}