import './app.scss'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { REDIRECT_URL } from './constants'

// window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
//   alert('Error occured: ' + errorMsg);//or any message
//   return false;
// }

function App() {
  const navigate = useNavigate()

  if (window.location.pathname === '/') {
    window.location.href = REDIRECT_URL
  }

  return <div className='p-4'>
    <Button variant='primary' onClick={() => navigate('/quote')}>Get My Quote</Button>
  </div>
}

export default App;
