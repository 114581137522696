import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import party from 'party-js'

function getFreeMows() {
  const rand = Math.random();
    
  if (rand < 0.90) {
      return 1
  } else if (rand < 0.99) {
      return 2
  } else {
      return 3
  }
}


export function TwentyOff() {
  const navigate = useNavigate()

  // go straight to the quote page
  useEffect(() => {
    navigate('/quote?d=early-bird')
  }, [navigate])
}


export default function Banner() {
  const ref = useRef(null)
  const navigate = useNavigate()
  const [alertMessage, setAlertMessage] = useState('Answer the following question to get one to three free mows.')
  const [alertVariant, setAlertVariant] = useState('dark')

  const question = 'When was the city of Huntsville incorporated?'
  const potentialAnswers = [
    '1892',
    '1811',
    '1845',
    '1791'
  ]

  function onClick(e) {
    e.preventDefault()
    
    const answer = e.target.innerText
    if (answer === '1811') {
      setAlertVariant('success')
      setAlertMessage('Congratulations! You got it right!')

      if (ref.current) {
        party.confetti(ref.current, {count: 100})
      }

      setTimeout(() => {
        navigate('/quote?m=' + getFreeMows())
      }, 2000)
    } else {
      setAlertVariant('warning')
      setAlertMessage('Sorry, that is not the correct answer. Please try again.')
    }
  }

  return (
    <div className='w-100 m-h-100vh d-flex justify-content-center' style={{
      backgroundImage: 'url(/yard1.png)',
      backgroundSize: 'cover'
    }}>
      <div className='mt-3 ps-2 pe-2' style={{maxWidth: '700px'}}>
        <div className='display-1'>Early Bird Promotion</div>

        <Alert variant={alertVariant} className='mt-3' ref={ref}>
          <span className='fw-bold'>Trivia Question:</span> {alertMessage}
        </Alert>

        <Form>
          <div className='display-6'>{question}</div>
          {potentialAnswers.map((answer) => (
            <div key={answer}>
              <Button className='ps-0 mt-3 w-100' style={{minWidth: '350px', minHeight: '75px'}} size='lg' variant='info' type='submit' onClick={onClick}>{answer}</Button>
            </div>
          ))}
        </Form>
      </div>
    </div>
  )
}