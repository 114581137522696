import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import YardButton from '../yardButton'

export default function Scope(props) {
  const [flowerBed, setFlowerBed] = useState(props.data.flowedBed || false)
  const [overgrown, setOvergrown] = useState(props.data.overgrown || false)

  function onSubmit(e) {
    e.preventDefault()
    const data = {
      flowerBed,
      overgrown
    }
    
    props.onSubmit(data)
  }


  return <Form onSubmit={onSubmit} className='d-flex flex-column'>
      <div className='mb-3'>
        <div className='display-6'>About your yard</div>
        <Form.Switch disabled={false} defaultChecked={flowerBed} className='form-control-lg mb-0 pb-0' name='flowerBed' label='Flower bed' onChange={e => setFlowerBed(e.target.checked)} />
        <div className='text-muted'>Do you need your flower bed maintained?</div>
        <Form.Switch disabled={false} defaultChecked={overgrown} className='form-control-lg mb-0 pb-0' name='overgorwn' label='Overgrown lawn' onChange={e => setOvergrown(e.target.checked)} />
        <div className='text-muted'>Is the lawn overgrown? The first service might involve repeated mowing to get it into shape.</div>
      </div>

      <div className='mb-3'>
        <div className='display-6'>Included services</div>
        <Form.Check disabled={true} checked={true} className='form-control-lg mb-0 pb-0' name='mowing' label='Mowing' />
        <div className='text-muted'>We will mow all areas with grass to your desired height preference.</div>
        <Form.Check disabled={true} checked={true} className='form-control-lg mb-0 pb-0' name='mowing' label='Weed eating' />
        <div className='text-muted'>We will trim all areas that the mower cannot reach.</div>
        <Form.Check disabled={true} checked={true} className='form-control-lg mb-0 pb-0' name='mowing' label='Edging' />
        <div className='text-muted'>We will edge all areas between concrete and your lawn.</div>
        <Form.Check disabled={true} checked={true} className='form-control-lg mb-0 pb-0' name='blowing' label='Blowing' />
        <div className='text-muted'>We will blow all clippings from your driveway, sidewalks, and patios.</div>
      </div>

      <YardButton className='mt-2 mb-3' variant='primary' type='submit'>Next</YardButton>
    </Form>
}