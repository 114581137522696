import party from 'party-js'
import _ from 'lodash'
import {useRef} from 'react'

export default function ThankYou() {
  const ref = useRef(null)
  _.defer(() => {
    if (!ref.current) {
      return
    }

    party.confetti(ref.current, {
      count: 100
    })
  }, 1000)

  return <div className='p-4' style={{maxWidth: '600px'}}>
    <div className='display-2' ref={ref}>Thank you!</div>
    <div className='lead mb-4'>
      You're all set. We'll be in touch shortly.
    </div>
    <div>
      <div className='h4 mb-3 mt-3'>Customer Support</div>
      <div className='mb-2'>Do you have a question or need to speak with us?</div>
      <ul className='list-group'>
        <li className='list-group-item'><a href='tel:1-256-400-0022'>Call or text us at <span>256-400-0022</span></a></li>
        <li className='list-group-item'><a href='mailto:hi@yard.bot'><span>Email us at hi@yard.bot</span></a></li>
      </ul>
    </div>
    </div>
}