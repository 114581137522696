import {useState, useEffect} from 'react'
import {
  useStripe,
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import YardButton from '../yardButton'

export default function DepositDetails({quoteId, amount}) {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  async function onSubmit(e) {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + '/complete?id=' + quoteId,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setLoading(false);
  }

  const paymentElementOptions = {
    layout: 'tabs'
  }

  return (
      <Form className='d-flex flex-column' onSubmit={onSubmit}>
        <PaymentElement options={paymentElementOptions} />
        {message && <Alert variant='dark' className='mt-3 mb-2'>
          <div className=''>{message}</div>
        </Alert>}
        <YardButton disabled={loading || !stripe || !elements} className='mt-4 mb-3' variant='primary' type='submit'>Pay ${amount / 100}</YardButton>
      </Form>
  )
}