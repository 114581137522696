import { BACKEND_URL } from './constants';

export default class Api {
  static async createDepositPaymentIntent({
    quoteId,
    amount,
    name,
    email,
    phone,
    address
  }) {
    const response = await fetch(`${BACKEND_URL}/payment-intents/deposits`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        quote_id: quoteId,
        amount,
        name,
        email,
        phone,
        address
      })
    })

    const data = await response.json();
    return data.client_secret
  }

  static async updateQuote(data) {
    const response = await fetch(`${BACKEND_URL}/quotes/${data.id}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)})

    return response.json()
  }
}