import { DepositStep } from './quote/deposit'
import { useLocation } from 'react-router-dom'
import { generateId } from './utils'
import { DEPOSIT_AMOUNT } from './constants'
import { DEPOSIT_HEADER } from './quote'

export default function DepositStandalone() {
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const existingId = queryParams.get('id')

  const id = existingId || generateId()
  return <div className='p-4' style={{maxWidth: '600px'}}>
    <div className='display-4 mb-2'>{DEPOSIT_HEADER}</div>
    <div className='mb-0'>
      <DepositStep data={{id, name: '', email: '', phone: '', addressParsed: {}}} amount={DEPOSIT_AMOUNT} />
    </div>
  </div>
}